import moment from "moment";
const PARTNER_STATUS = {
  Active: {
    value: "Aktif",
    style: { color: "#2eb85c" },
  },
  InActive: {
    value: "Pasif",
    style: { color: "#1EA7E5" },
  },
  Pending: {
    value: "Bekliyor",
    style: { color: "#E2E51E" },
  },
  Banned: {
    value: "Engellendi",
    style: { color: "#E51E1E" },
  },
  Deleted: {
    value: "Silindi",
    style: { color: "#E51E1E" },
  },
};
export const partnersColumnsDefs = (currency) => [
  {
    field: "status",
    cellRenderer: (params) => PARTNER_STATUS[params.value]?.value || "Belirsiz",
    cellStyle: (params) => ({
      ...PARTNER_STATUS[params.value]?.style,
      "font-weight": "bold",
    }),

    headerName: "Durum",
    width: 100,
    suppressSizeToFit: true,
  },
  {
    field: "title",
    headerName: "Partner Ünvanı",
  },
  {
    field: "brand",
    headerName: "Partner Markası",
  },
  {
    field: "balance",
    headerName: "Bakiye",
    type: "rightAligned",
    width: 120,
    cellRenderer: (params) => {
      return params.value?.toLocaleString("tr-TR", {
        style: "currency",
        currency: "TRY",
      });
    },
  },
  {
    field: "creditLimit",
    headerName: "Cari Kredi Limiti",
    type: "rightAligned",
    width: 140,
    cellRenderer: (params) => {
      return params.value?.toLocaleString("tr-TR", {
        style: "currency",
        currency: "TRY",
      });
    },
  },
  {
    field: "coverageAmount",
    headerName: "Teminat Bedeli",
    type: "rightAligned",
    width: 140,
    cellRenderer: (params) => {
      return params.value?.toLocaleString("tr-TR", {
        style: "currency",
        currency: "TRY",
      });
    },
  },
  {
    field: "commissionRate.daily",
    headerName: "Komisyon Oranı (Günlük)",
    type: "rightAligned",
    width: 140,
    cellRenderer: (params) => {
      return params.value;
    },
  },
  {
    field: "commissionRate.monthly",
    headerName: "Komisyon Oranı (Aylık)",
    type: "rightAligned",
    width: 140,
    cellRenderer: (params) => {
      return params.value;
    },
  },
  {
    field: "priceManipulation.daily",
    headerName: "Fiyat Yükselt (Günlük)",
    type: "rightAligned",
    width: 140,
    hide: true,
    cellRenderer: (params) => {
      return params.value;
    },
  },
  {
    field: "priceManipulation.monthly",
    headerName: "Fiyat Yükselt (Aylık)",
    type: "rightAligned",
    width: 140,
    hide: true,
    cellRenderer: (params) => {
      return params.value;
    },
  },
  {
    field: "partnerAverage",
    valueGetter: (params) => {
      return params.value?.active.TRY;
    },
    cellRenderer: (params) => {
      return params?.data?.partnerAverage?.active
        ? params?.data?.partnerAverage?.active[currency].toLocaleString(
            "tr-TR",
            {
              style: "currency",
              currency,
            }
          )
        : 0;
    },
    type: "rightAligned",
    headerName: "Devam Eden Rezervasyonların Toplam Tutarı",
    width: 200,
  },
  {
    field: "partnerAverage",
    valueGetter: (params) => {
      return params.value?.approved.TRY;
    },
    cellRenderer: (params) => {
      return params?.data?.partnerAverage?.approved
        ? params?.data?.partnerAverage?.approved[currency].toLocaleString(
            "tr-TR",
            {
              style: "currency",
              currency,
            }
          )
        : 0;
    },
    type: "rightAligned",
    headerName: "Gelecek Rezervasyonların Toplam Tutarı",
    width: 200,
  },
  {
    field: "partnerAverage",
    valueGetter: (params) => {
      return params.value?.completed.TRY;
    },
    cellRenderer: (params) => {
      return params?.data?.partnerAverage?.completed
        ? params?.data?.partnerAverage?.completed[currency].toLocaleString(
            "tr-TR",
            {
              style: "currency",
              currency,
            }
          )
        : 0;
    },
    type: "rightAligned",
    headerName: "Mevcut Ayın Rezervasyonlarının Toplam Tutarı",
    width: 200,
  },
  {
    field: "address",
    headerName: "Adres",
  },
  {
    field: "phone",
    headerName: "Telefon",
  },
  {
    field: "email",
    headerName: "E-Posta",
  },
  {
    field: "taxOffice",
    headerName: "Vergi Dairesi",
  },
  {
    field: "taxNumber",
    headerName: "Vergi Numarası",
  },
  {
    field: "mersisNumber",
    headerName: "Mersis Numarası",
  },
  {
    field: "createdAt",
    headerName: "Oluşturma Tarihi",
    cellRenderer: (params) => {
      return moment(params.value).format("DD.MM.YYYY - HH:mm");
    },
  },
];

export const locationInfosDefs = [
  {
    field: "locationName",
    headerName: "Lokasyon Adı",
    width: 200,
  },
  {
    field: "lastSearchDate",
    headerName: "Son Arama Tarihi",
    width: 200,
  },
  {
    field: "carCount",
    headerName: "Gelen Araç Sayısı",
    width: 200,
  },
  {
    field: "vendorCount",
    headerName: "Gelen Tedarikçi Sayısı",
    width: 200,
  },
  {
    field: "averageDayPrice",
    headerName: "Ortalama Günlük Fiyat",
    width: 200,
  },
];
export const userPartnersColumnsDefs = [
  {
    field: "status",
    cellRenderer: (params) => PARTNER_STATUS[params.value]?.value || "Belirsiz",
    cellStyle: (params) => ({
      ...PARTNER_STATUS[params.value]?.style,
      "font-weight": "bold",
    }),

    headerName: "Durum",
    width: 100,
    suppressSizeToFit: true,
  },

  {
    field: "name",
    headerName: "İsim",
  },
  {
    field: "username",
    headerName: "Kullanıcı Adı",
  },
  {
    field: "lastname",
    headerName: "Soy İsim",
  },
  {
    field: "email",
    headerName: "E-Posta",
  },
  {
    field: "isApiUser",
    headerName: "Api",
    cellRenderer: (params) => {
      return params.value ? "Var" : "Yok";
    },
  },
  {
    field: "partnerID.title",
    headerName: "Partner İsmi",
  },
  {
    field: "createdAt",
    headerName: "Oluşturma Tarihi",
    cellRenderer: (params) => {
      return moment(params.value).format("DD.MM.YYYY - HH:mm");
    },
  },
];
